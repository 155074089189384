import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons'

const EcoChange = ({ location }) => {

	const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "illust/ecochange-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			ecoChangeLogo: file(relativePath: { eq: "ecochange_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			overview: file(relativePath: { eq: "ecochange_overview_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			customer1: file(relativePath: { eq: "casestudy_ecochange_customer01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			customer2: file(relativePath: { eq: "casestudy_ecochange_customer02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      internetIcon: file(relativePath: { eq: "icons/internet.svg" }) {
        publicURL
      },
      startIcon: file(relativePath: { eq: "icons/start.svg" }) {
        publicURL
      },
      cloudUploadIcon: file(relativePath: { eq: "icons/cloud-upload.svg" }) {
        publicURL
      },
      earthIcon: file(relativePath: { eq: "icons/earth.svg" }) {
        publicURL
      }
    }
  `)

	return (
		<Layout location={location} title="EcoChange" >
			<SEO title="EcoChange" description="「EcoChange」とは、簡単・便利・低コストを⽬指し、⼤⼿・中堅から中⼩企業までの受発注業務に最適化、標準化したＥＤＩです。" />

			<div className="page-head">
				<Container expand="lg">
					<BackgroundImage fluid={data.background.childImageSharp.fluid} id="page-head-image" >
						<Img fluid={data.ecoChangeLogo.childImageSharp.fluid} className="mb-5" alt="EcoChangeロゴ" />
						<h1>
							<span className="p-blue">企業規模、業種</span>を問わず、<br className="d-none d-lg-block" />
							⾒積から受発注、請求、⽀払まで企業間取引の<span className="p-blue">デジタル化</span>を実現。
						</h1>
						<p className="mb-5">
							「EcoChange」とは、簡単・便利・低コストを⽬指し
							⼤⼿・中堅から中⼩企業までの受発注業務に最適化、標準化したＥＤＩです。<br className="d-none d-lg-block" />
							インターネット回線を利⽤しているので、2024年の通信回線のIP網移⾏にも対応しています。<br className="d-none d-lg-block" />
							クラウド・オンプレ問わず、APIで貴社基幹システムとシームレスに連携。<br className="d-none d-lg-block" />
							スマートフォン・タブレット対応なので、PCがなくてもいつでもどこでも受発注業務ができます。<br className="d-none d-lg-block" />
						</p>
						<Button
							variant="primary"
							className="py-4 px-5"
							href="https://www.ecochange.jp/"
							target="_blank" rel="noopener noreferrer"
						>
							EcoChange 公式サイトはこちら
						</Button>
					</BackgroundImage>
				</Container>
				<div className="bottom-border" />
			</div>

			<Container expand="lg" className="text-center">
				<h3 className="mb-5">サービス概要</h3>
				<p className="mb-5">EDIサービスとは「電⼦データ交換」のことで、異なる企業間で、商取引のためのデータを交換する仕組みの事を⾔います。</p>
				<Img fluid={data.overview.childImageSharp.fluid} className="mx-auto mb-6" alt="サービス概要" />

				<h3 className="mb-5">EcoChangeの特徴</h3>
				<ul className="d-inline-block text-left mb-5">
					<li>EcoChangeは国連CEFACTの国際EDIに準拠したクラウド型XML-EDI。</li>
					<li>中⼩企業庁が推奨する中⼩企業共通EDI準拠。</li>
					<li>多端末問題、多画⾯問題を解決するシングルウインドウ・シングルインターフェイスを実現。</li>
					<li>基幹システムや各種アプリケーションとのシームレスな連携を実現。</li>
					<li>クラウドサービスによる提供。</li>
					<li>OBC「商蔵奉⾏」、PCA「商魂」「商管」とシームレスな連携が可能です。商奉⾏︓受注伝票データ、蔵奉⾏︓発注伝票データ</li>
					<li>iOSに対応。いつでもどこでも受発注データのチェックと発注業務が⾏えます。</li>
					<li>
						FAXとの共存が可能なハイブリットEDI。
					</li>
					<li>
						全銀EDIシステム（ZEDI）連携。売掛⾦⾃動消込など経理業務の効率化を実現。
						<a href="https://www.ecochange.jp/ecochange-for-zedi/" target="_blank" rel="noopener noreferrer" >※オプション・詳細はこちら。</a>
					</li>
				</ul>

				<div className="mb-6">
					<div className="ecochnage-icons">
						<img src={data.internetIcon.publicURL} alt="通信⽅式の変更をスムーズに" />
						<p>通信⽅式の変更を<br />スムーズに</p>
					</div>
					<div className="ecochnage-icons">
						<img src={data.startIcon.publicURL} alt="すぐに始められる" />
						<p>すぐに始められる<br />{`　`}</p>
					</div>
					<div className="ecochnage-icons">
						<img src={data.cloudUploadIcon.publicURL} alt="取引先を⼀元管理" />
						<p>取引先を⼀元管理<br />{`　`}</p>
					</div>
					<div className="ecochnage-icons">
						<img src={data.earthIcon.publicURL} alt="国連CEFACTに準拠" />
						<p>国連CEFACTに準拠<br />{`　`}</p>
					</div>
				</div>

				<h3 className="mb-5">機能概要</h3>
				<Row className="text-left mb-6">
					<Col lg={3}><p><strong>⾃動インストール機能</strong></p></Col>
					<Col lg>
						<p>
							EcoChangeを起動後、⾃動インストール機能により、ご使⽤のクライアントへインストールが⾏われます。<br />
							⾃動でインストールされるため、意識することなく、常に最新版をご利⽤いただけます。<br />
							各種設定情報はサーバーに保存されるため、ご使⽤のクライアントは制限されません。
						</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>⾃動処理機能</strong></p></Col>
					<Col lg>
						<p>⾃動印刷機能で、FAXの代わりとして使⽤出来ます。注⽂情報をCSVデータとして⾃動取得出来ます。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>トランスレーター機能</strong></p></Col>
					<Col lg>
						<p>マッピング機能で、⾃社情報の変更は最⼩限に抑える事が出来ます。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>ファイル添付とメール機能</strong></p></Col>
					<Col lg>
						<p>
							注⽂情報に仕様書・CAD図⾯などのファイルを添付できます。<br />
							注⽂情報をアップロードする際に、仕⼊先に注⽂情報送信の通知メールが送信されます。注⽂情報にコメントを付加できます。
						</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>
				</Row>

				<h3 className="mb-5">導⼊事例</h3>
				<Row className="text-left">
					<Col lg={6} className="mb-5">
						<div className="shadow-box h-100">
							<h2 className="mb-5">#01 宮地電機株式会社様</h2>
							<h2>今後は30社との連携を⽬指します。</h2>
							<h3>経営管理室 中⽥様</h3>
							<p className="mb-5">
								早くからEDIを実現し、2014年からEcoChangeに切替え、サプライヤともスムーズに連携し、発注・納期回答の作業が簡素化され、業務が効率的になりました。<br />
								<a href="https://www.ecochange.jp/work/case1/" target="_blank" rel="noopener noreferrer" >
									<FontAwesomeIcon icon={faHandPointRight} /> 詳細を見る
								</a>
							</p>
							<Img fluid={data.customer1.childImageSharp.fluid} className="ml-auto" alt="宮地電機株式会社様" />
						</div>
					</Col>
					<Col lg={6} className="mb-5">
						<div className="shadow-box h-100">
							<h2 className="mb-5">#02 株式会社フジデン様</h2>
							<h2>横展開して、全体的に効率化していきたい。</h2>
							<h3>四国営業所 岡島様</h3>
							<p className="mb-5">
								メールやFAXでの注⽂で発⽣する通信費や⽤紙代のランニングコストをEcoChangeの導⼊後通信費や⽤紙代の削減に繋がりました。<br />
								<a href="https://www.ecochange.jp/work/case2/" target="_blank" rel="noopener noreferrer" >
									<FontAwesomeIcon icon={faHandPointRight} /> 詳細を見る
								</a>
							</p>
							<Img fluid={data.customer2.childImageSharp.fluid} className="ml-auto" alt="株式会社フジデン様" />
						</div>
					</Col>
				</Row>
			</Container>
		</Layout>
	)
}

export default EcoChange